<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
  >
    <template v-slot:activator="{ on }">
      <v-icon
        :size="size"
        class="mx-2"
        name="delete"
        :title="'delete ' + id"
        :disabled="disabled"
        v-on="on"
      >
        mdi-delete
      </v-icon>
    </template>
    <v-card
      title="Are you sure?"
      class="mb-0"
    >
      <v-card-title class="ma-2 text-center d-block">
        <span style="color: red">{{ $t('Delete.proceed') }}</span>
        <br>
        <span style="color: red">{{ $t('Delete.undone') }}</span>
      </v-card-title>
      <v-divider class="ma-2" />
      <v-card-actions class="ma-2 text-center">
        <v-spacer />
        <v-btn
          color="white"
          name="Cancel"
          class="text-right mr-10"
          @click="_onNo()"
        >
          NO
        </v-btn>
        <v-btn
          color="red"
          name="Add"
          class="text-right ml-10"
          @click="_onYes()"
        >
          {{ $t('Delete.agree') }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: null
    },
    size: {
      type: Number,
      default: 16
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  created() {
    this.$emit('closeDialog');
  },
  methods: {
    _onYes() {
      this.$emit('deleted', this.id);
      this.dialog = false;
    },
    _onNo() {
      this.dialog = false;
    }
  }
};
</script>
